@charset "utf-8";

$bgimg: "/assets/bg.png";

.logobox {
    text-align: left;
    overflow: hidden;
}

.logo {
    width: 30%;
    margin-top: -0.4em;
    margin-bottom: -0.4em;
    margin-left: 1em;
    float: left;
}

.logotext {
    font-family: serif;
    font-size: 200%;
    color: #AA3333;
}

.content {
    width: 60%;
    min-width: 650px;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    background-color: #AAAAAA;
    background-image: url($bgimg);
}

.navbar {
    width: 100%;
    background-color: #000000;
    border-width: 0;
}

.navbar ul {
  overflow: hidden;
  margin: 0;
  list-style: none;
  border-width: 0;
}

.navbar ul li {
  a:link, a:visited, div {
    float: left;
    line-height: normal;
    margin: 0;
    display: block;
    padding: 0.5em 1em 0.5em 1em;
    vertical-align: middle;
    text-align: justify;
    border: 0px solid #000000;
    color: #FFFFFF;
    font-family: sans-serif;
    text-decoration: none;
    outline: 0;
  }
  a:hover, a:active, div:hover {
    background-color: #444444;
  }
}

.maintitle {
    color: #AA3333;
}

.maindiv {
    border: 10px solid #AA3333;
    border-radius: 15px;
    border-top-right-radius: 0;
    padding: 1em 3em 2em 1.5em;
    color: #000000;
    box-shadow: 8px 8px #555555;
    margin-top: 10px;
    background-color: #DD9999;
}

.maincontent {
    display: block;
}

.imgright, .imgleft {
    width: 25%;
    border: 2px solid #AA3333;
    margin-bottom: 0.4em;
}

.imgright {
    float: right;
    margin-left: 1em;
}

.imgleft {
    float: left;
    margin-right: 1em;
}

.employee {
    float: none;
    clear: both;
    margin-bottom: 1em;
}

.employee img {
    float: left;
    width: 7em;
    margin-right: 1em;
    margin-bottom: 1em;
    border: 2px solid #AA3333;
}

:lang(de)    { quotes:"\201E" "\201C" "\201A" "\2018"; }

.quote, .quoteu {
    float: none;
    clear: both;
    text-align: right;
    font-style: italic;
    margin-top: 0.5em;
    margin-bottom: 0;
}

.quote:before { content:open-quote;  }
.quote:after  { content:close-quote; }

.quotesource {
    margin-top: 0;
    text-align: right;
    font-style: normal;
    font-variant: small-caps;
}

.prc {
    font-style: italic;
}

.extern:after {
    content: "\2197";
}

.bullets {
  list-style-type: circle;
}
